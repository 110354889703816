var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('account-role-title',{attrs:{"role":"Admin"}}),_c('span',{staticClass:"title d-block mb-2"},[_vm._v(_vm._s(_vm.$t("Upgrade Requests")))]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sellerRequestsList,"options":_vm.options,"server-items-length":_vm.totalRequests,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': _vm.$store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': _vm.loading,
        'disable-items-per-page': _vm.loading,
      },"items-per-page":_vm.globalItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.updateItemsPerPage,"update:page":_vm.getSellerRequestsFromApi,"update:sort-by":_vm.getSellerRequestsFromApi},scopedSlots:_vm._u([{key:"item.access",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{staticStyle:{"width":"max-content"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"success","x-small":""},on:{"click":function($event){return _vm.changeStatus(item.id,1, item.market_id)}}},[_vm._v(_vm._s(_vm.$t('Approve')))])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Reject'))+" ")])]}}],null,true),model:{value:(_vm.dialogBan[item.id]),callback:function ($$v) {_vm.$set(_vm.dialogBan, item.id, $$v)},expression:"dialogBan[item.id]"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"v-model":_vm.valid}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('Reject Seller Request')))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","name":"input","rules":_vm.noteRules,"label":"Outlined textarea"},model:{value:(_vm.rejectNote),callback:function ($$v) {_vm.rejectNote=$$v},expression:"rejectNote"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogBan = []}}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")]),_c('v-btn',{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.changeStatus(item.id,2, item.market_id)}}},[_vm._v(" "+_vm._s(_vm.$t('Reject'))+" ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"data":item.id},on:{"click":function($event){return _vm.$router.push({name:'user', params:{id: item.user_id}})}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }