<template>
<div>
  <account-role-title role="Admin" />
  <!-- <breadcrumbs /> -->
  <span class="title d-block mb-2">{{ $t("Upgrade Requests") }}</span>
  <v-data-table
      :headers="headers"
      :items="sellerRequestsList"
      :options.sync="options"
      :server-items-length="totalRequests"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $store.getters['nav/itemsPerPageOptions'],
        'disable-pagination': loading,
        'disable-items-per-page': loading,
      }"
      @update:items-per-page="updateItemsPerPage"
      @update:page="getSellerRequestsFromApi"
      @update:sort-by="getSellerRequestsFromApi"
      :items-per-page="globalItemsPerPage"
  >
    <template #[`item.access`]="{item}">
      <v-row style="width: max-content">
        <v-col cols="4">
          <v-btn color="success" x-small @click="changeStatus(item.id,1, item.market_id)">{{ $t('Approve') }}</v-btn>
        </v-col>
        <v-col cols="4">
          <v-dialog
              v-model="dialogBan[item.id]"
              persistent
              max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error"
                     v-bind="attrs"
                     v-on="on"
                     x-small>
                {{ $t('Reject') }}
              </v-btn>
            </template>
            <v-card>
              <v-form ref="form" :v-model="valid">
                <v-card-title>
                  <span class="text-h5">{{ $t('Reject Seller Request') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            outlined
                            name="input"
                            :rules="noteRules"
                            label="Outlined textarea"
                            v-model="rejectNote"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogBan = []"
                  >
                    {{ $t('Close') }}
                  </v-btn>
                  <v-btn
                      color="error darken-1"
                      text
                      @click="changeStatus(item.id,2, item.market_id)"
                  >
                    {{ $t('Reject') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-icon class="cursor-pointer" :data="item.id" @click="$router.push({name:'user', params:{id: item.user_id}})">
            {{ icons.mdiEyeOutline }}
          </v-icon>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</div>
</template>

<script>
import {mdiSquareEditOutline, mdiDotsVertical, mdiMenuDown, mdiEyeOutline} from '@mdi/js'
import {getCurrentInstance, ref, watch, computed} from "@vue/composition-api";
import axios from "@axios";
import store from '@/store'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const options = computed(({
      get() {
        return store.state.pagination.upgradeRequests
      },
      set(val) {
        let ob = {}
        let page = val.page > val.lastPage ? 1 : val.page
        store.commit("pagination/SET_UPGRADE_REQUESTS_PAGINATION", Object.assign({}, val, {
          page
        }));
      }
    }))
    const loading = ref(true)
    const sellerRequests = ref([])
    const totalRequests = ref(150)
    const dialog = ref([])
    const rejectNote = ref('')
    const noteRules = [v => !!v || 'Reject note required', v => v.length > 20 || 'Minimal length of characters is 20']
    const valid = ref(false)
    const form = ref(null)
    const dialogBan = ref([])
    const changeStatus = (id, status) => {
      if (status == 2 && !form.value.validate()) {
        return
      }
      axios.post(`admin/approve-seller/${id}`, {status, id, note: rejectNote.value, _method: 'put'})
          .then(res => {
            getSellerRequestsFromApi()
            let statusText = 'updated'
            switch (status) {
              case 1:
                statusText = 'approved';
                break;
              case 2:
                statusText = 'rejected';
                break;
            }
            vm.$notify({
              group: 'deals',
              title: vm.$t(`Seller ${statusText}`),
              type: 'success'
            })
            dialog.value = []
          })
          .catch()
    }

    const getSellerRequestsFromApi = () => {
      loading.value = true
      const {groupBy, groupDesc, multiSort, mustSort, page, sortBy, sortDesc} = options.value
      axios.get('admin/seller-requests', {
        params: {
          pagination: globalItemsPerPage.value,
          page,
          sort_field: sortBy && sortBy[0] ? sortBy[0] : [],
          sort_dir: sortDesc && sortDesc[0] ? 'DESC' : 'ASC',
          status: 1
        }
      }).then(res => {
        sellerRequests.value = res.data.data.map(e => Object.assign(e, {access: ''}))
        totalRequests.value = res.data.meta.total
        store.commit("pagination/SET_UPGRADE_REQUESTS_PAGINATION", {itemsPerPage: globalItemsPerPage.value, page, lastPage: res.data.meta.last_page})
        loading.value = false
      }).catch(e => console.log(e))
    }

    const globalItemsPerPage = computed(() => store.getters['nav/globalItemPerPage'])

    const updateItemsPerPage = (e) => {
      store.commit("nav/SET_GLOBAL_ITEMS_PER_PAGE", e)
      getSellerRequestsFromApi()
    }

    getSellerRequestsFromApi()

    return {
      updateItemsPerPage,
      globalItemsPerPage,
      changeStatus,
      getSellerRequestsFromApi,
      valid,
      form,
      noteRules,
      rejectNote,
      options,
      loading,
      dialog,
      sellerRequests,
      totalRequests,
      dialogBan,
      headers: [
        {text: 'Request date', value: 'created_at', width: 130},
        {text: 'id', value: 'user_id', width: 65},
        {text: 'Name', value: 'user_name'},
        {text: 'Email', value: 'user_email'},
        {text: 'Description', value: 'description'},
        {text: '', value: 'access', sortable: false, width: 10}
      ],
      sellerRequestsList: sellerRequests,
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiMenuDown,
        mdiEyeOutline
      },
    }
  },
}
</script>